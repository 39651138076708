import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import pressBgImage from '../images/pages/press/press.png';

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}


const PressPage = ({ data }) => {
  const { pageTitle, metaTitle, pressBgImage, pressMainText } = data.contentfulPress;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero-shallow" style={{backgroundImage:`url(${pressBgImage.file.url})`}}>
          <div className="hero-overlay"></div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="hero-text-container">
                    <h1>{pageTitle}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="press full-width-layout">
          <Container fluid={true}>
            {documentToReactComponents(pressMainText.json, options)}
          </Container>
        </div>
    </Layout>
  );
};

export default PressPage;

export const pageQuery = graphql`
  query {
    contentfulPress {
      pressBgImage {
        file {
          url
        }
      }
      pressMainText {
        json
      }
      metaTitle
      pageTitle
      slug
    }
  }
`;